import { createSlice } from '@reduxjs/toolkit'
import { setTodoItemCountAction } from './CoreSlice'

export const lmsSlice = createSlice({
  name: 'lms',
  initialState: {
    combinedContentMap: {},
    combinedSortedContentArray: []
  },
  reducers: {
    setCombinedContentMapAction: (state, action) => {
      state.combinedContentMap = action.payload
    },
    setSortedContentArrayAction: (state, action) => {
      state.combinedSortedContentArray = action.payload
    },
    setCourseProgressAction: (state, action) => {
      const { lessonLocation, suspendData, courseId } = action.payload
      const newMap = { ...state.combinedContentMap }
      if (lessonLocation) {
        newMap[courseId].lesson_location = lessonLocation
      }
      if (suspendData) {
        newMap[courseId].suspend_data = suspendData
      }
      state.combinedContentMap = newMap
    }
  }
})

export const {
  setCombinedContentMapAction,
  setSortedContentArrayAction,
  setCourseProgressAction
} = lmsSlice.actions

export const populateContentMaps = ({ trainings, policies }) => {
  return async (dispatch) => {
    if (trainings && policies) {
      const userContentArray = [...trainings, ...policies].sort((a, b) => b.assignmentDate - a.assignmentDate)
      const userContentMap = userContentArray.reduce((map, content) => ({ ...map, [content.id]: content }), {})

      dispatch(setCombinedContentMapAction(userContentMap))
      dispatch(setSortedContentArrayAction(userContentArray))

      // Calculate how many incomplete assignments we have and update accordingly
      const incompleteAssignments = userContentArray.filter(content => (content.lesson_status !== 'passed' && content.acknowledged !== true))
      dispatch(setTodoItemCountAction(incompleteAssignments.length))
    }
  }
}

export default lmsSlice.reducer

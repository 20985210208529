import introJs from 'intro.js'
import { useEffect, useState } from 'react'
import 'intro.js/introjs.css'
import { useDispatch } from 'react-redux'
import { notificationOpenedThunk } from '../../redux/slices/NotificationsSlice.js'
import { NOTIFICATIONS } from '../../utils/FrontEndConstants.js'
import { Alert, Grid } from '@mui/material'

function BasicViewer ({ learningMomentData, token, darkMode }) {
  const dispatch = useDispatch()
  const emailDate = new Date()

  async function getIpAddressAndLoadLearningMoment () {
    let userIP = null
    try {
      const getIpAddressResponse = await fetch('https://api.ipify.org?format=json&callback=getIP', {
        method: 'GET'
      })

      if (getIpAddressResponse.status === 200) {
        const { ip } = await getIpAddressResponse.json()
        userIP = ip
      }
    } catch (error) {
      // Ignore error and send null userIP
    }

    fetch(`/api/open-lm?token=${token}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ userIP })
    })
  }

  useEffect(() => {
    const totalSteps = document.querySelectorAll('[data-intro]').length
    let lastThreshold = -1
    const progressThresholds = [0, 0.25, 0.50, 0.75, 0.95]

    function getThresholdIndex (progress) {
      let index = 0
      progressThresholds.forEach((threshold, i) => {
        if (progress > threshold) {
          index = i
        }
      })
      return index
    }

    getIpAddressAndLoadLearningMoment()

    setTimeout(() => {
      const intro = introJs().start()
      let currStep = 1
      let finishedTour = false
      intro.onbeforeexit((event) => {
        return finishedTour
      })
      intro.oncomplete(() => {
        finishedTour = true
        intro.addHints()
        fetch('/api/fin', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            c: token
          })
        })
        setInterval(() => {
          intro.showHints()
        }, 10)
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Learning Moment Complete!' }))
      })
      intro.onchange((ele) => {
        document.querySelectorAll('[data-intro]')
          .forEach((el, i) => { if (el === ele) currStep = i })
        const progress = currStep / totalSteps
        const thresholdIndex = getThresholdIndex(progress)
        if (lastThreshold < thresholdIndex) {
          lastThreshold = thresholdIndex
          fetch('/api/learning', {
            method: 'PUT',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              c: token,
              progress
            })
          })
          if (totalSteps - currStep <= 2) {
            fetch('/api/fin', {
              method: 'POST',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                c: token
              })
            })
          }
        }
      })
    }, 4000)
  }, [])

  if (learningMomentData.templateInfo.channel === 'sms') {
    const hint = 'If you recognize the sender make sure this is something you would expect to receive. If not, verify over the phone or in person.'

    return (

      <div className='lm-content-area'>
        <div className='lms-pt-2'>
          <h1 style={{ textAlign: 'center' }}>Something about this email is phishy! &#128032;</h1>
          <h2 style={{ textAlign: 'center' }}>Please read through all of the learning moments to submit this training as complete.</h2>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <h3 style={{ textAlign: 'center', fontSize: '18px' }}>
              You'll know the training has been completed once you see the familiar green banner appear at the top.
            </h3>
            <Grid item sx={{ marginLeft: 'var(--phin-s0)' }}>
              <Alert
                id='phin-alert-success-sample'
                aria-label='Phin Alert Success Sample'
                variant={darkMode ? 'standard' : 'filled'}
                severity='success'
                sx={{ width: '250px', borderRadius: '8px' }}
              >
                Learning Moment Complete!
              </Alert>
            </Grid>
          </Grid>
        </div>
        <div className='lms-mt-4 lms-mlr-5 container-padding-smallish email-receipt lm-small-view'>
          <div className='d-flex flex-row w-100 justify-content-start align-items-start'>
            <div className='d-flex flex-column justify-content-end align-items-end email-info-left'>
              <span> from: </span>
              <span> date: </span>
              <span> subject: </span>
            </div>
            <div className='d-flex flex-column justify-content-start align-items-start email-info-right'>
              <span
                data-step='1'
                data-intro={hint}
                data-hint={hint}
              > {`${learningMomentData.from_name} (${learningMomentData.templateInfo.fromNumber})`}
              </span>
              <span> {emailDate.toString()} </span>
              <span> <b>{learningMomentData.subject}</b></span>
            </div>
          </div>

        </div>
        <div className='lms-mt-2 lms-mlr-5 email-blackdrop container-padding-smallish lm-small-view'>
          <div>
            <p style={{ whiteSpace: 'break-spaces' }}>{learningMomentData.templateInfo.message}</p>
          </div>
        </div>
      </div>

    )
  } else {
    return (

      <div className='lm-content-area'>
        <div className='lms-pt-2'>
          <h1 style={{ textAlign: 'center' }}>Something about this email is phishy! &#128032;</h1>
          <h2 style={{ textAlign: 'center' }}>Please read through all of the learning moments to submit this training as complete.</h2>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <h3 style={{ textAlign: 'center', fontSize: '18px' }}>

              You'll know the training has been completed once you see the familiar green banner appear at the top.
            </h3>
            <Grid item sx={{ marginLeft: 'var(--phin-s0)' }}>
              <Alert
                id='phin-alert-success-sample'
                aria-label='Phin Alert Success Sample'
                variant={darkMode ? 'standard' : 'filled'}
                severity='success'
                sx={{ width: '250px', borderRadius: '8px' }}
              >
                Learning Moment Complete!
              </Alert>
            </Grid>
          </Grid>
        </div>
        <div className='lms-mt-4 lms-mlr-5 container-padding-smallish email-receipt lm-small-view'>
          <div className='d-flex flex-row w-100 justify-content-start align-items-start'>
            <div className='d-flex flex-column justify-content-end align-items-end email-info-left'>
              <span> from: </span>
              <span> reply-to: </span>
              <span> to: </span>
              <span> date: </span>
              <span> subject: </span>
            </div>
            <div className='d-flex flex-column justify-content-start align-items-start email-info-right'>
              <span> {`<${learningMomentData.from_email}>`}</span>
              <span> {`<${learningMomentData.reply_to}>`}</span>
              <span> {`<${learningMomentData.email}>`}</span>
              <span> {emailDate.toString()} </span>
              <span> <b>{learningMomentData.subject}</b></span>
            </div>
          </div>

        </div>
        <div className='lms-mt-2 lms-mlr-5 email-blackdrop lm-small-view'>
          <div dangerouslySetInnerHTML={{ __html: learningMomentData.templateInfo.html }} />
        </div>
      </div>
    )
  }
}

function PlatformVideoView ({ learningMomentData, token, darkMode }) {
  useEffect(() => {
    fetch(`/api/open-lm?token=${token}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      }
    })
    const player = document.getElementById('player')

    let lastThreshold = -1
    const progressThresholds = [0, 0.25, 0.50, 0.75, 0.95]

    function getThresholdIndex (progress) {
      let index = 0
      progressThresholds.forEach((threshold, i) => {
        if (progress > threshold) {
          index = i
        }
      })
      return index
    }

    player.ontimeupdate = () => {
      const progress = player.currentTime / player.duration
      const thresholdIndex = getThresholdIndex(progress)
      if (lastThreshold < thresholdIndex) {
        lastThreshold = thresholdIndex
        fetch('/api/learning', {
          method: 'PUT',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            c: token,
            progress
          })
        })
        if (progress >= 0.95) {
          lastThreshold = thresholdIndex
          fetch('/api/fin', {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              c: token
            })
          })
        }
      }
    }
  }, [])
  return (
    <div className='lm-content-area'>
      <div className='lms-mt-2'>
        <h1 style={{ textAlign: 'center' }}>Something about this email is phishy! &#128032;</h1>
        <h2 style={{ textAlign: 'center' }}>Please read through all of the learning moments to submit this training as complete.</h2>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <h3 style={{ textAlign: 'center', fontSize: '18px' }}>

            You'll know the training has been completed once you see the familiar green banner appear at the top.
          </h3>
          <Grid item sx={{ marginLeft: 'var(--phin-s0)' }}>
            <Alert
              id='phin-alert-success-sample'
              aria-label='Phin Alert Success Sample'
              variant={darkMode ? 'standard' : 'filled'}
              severity='success'
              sx={{ width: '250px', borderRadius: '8px' }}
            >
              Learning Moment Complete!
            </Alert>
          </Grid>
        </Grid>
      </div>
      <div className='container-padding-smallish lms-pt-2 lm-small-view'>
        <video className='lm-video-player' id='player' controls>
          <source src={`/api/video/${learningMomentData.templateInfo.learningUrl.split('/')[1]}`} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

function CustomRedirectView ({ learningMomentData, token }) {
  const [isDone, setIsDone] = useState(false)
  useEffect(() => {
    async function fetchData () {
      const promises = []
      promises.push(
        fetch(`/api/open-lm?token=${token}`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' }
        }))
      promises.push(
        fetch('/api/learning', {
          method: 'PUT',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            c: token,
            progress: 1
          })
        }))
      promises.push(
        fetch('/api/fin', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ c: token })
        }))

      await Promise.all(promises)
      setIsDone(true)
      window.location.href = learningMomentData.templateInfo.learningUrl
    }
    if (learningMomentData.templateInfo.learningUrl) { fetchData() }
  }, [learningMomentData])

  return (
    <>
      {isDone ? <div /> : (<div>Redirecting</div>)}
    </>
  )
}

function LearningMomentViewer ({ token, learningMomentData, darkMode }) {
  let view

  if (learningMomentData.templateInfo.learningType === 'lm') {
    view = <BasicViewer token={token} learningMomentData={learningMomentData} darkMode={darkMode} />
  } else if (learningMomentData.templateInfo.learningType === 'video') {
    view = <PlatformVideoView token={token} learningMomentData={learningMomentData} darkMode={darkMode} />
  } else {
    // custom redirect
    view = <CustomRedirectView token={token} learningMomentData={learningMomentData} />
  }

  return view
}

export default LearningMomentViewer

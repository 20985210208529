import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import '../../LMSMain.css'
import phinDefault from '../../phinDefault.png'
import Tooltip from './Tooltip'
import { Badge, Box, Grid, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { IoNotifications, IoSunny, IoMoon } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useGetUserQuery } from '../../redux/API'
import { setDarkModeAction } from '../../redux/slices/CoreSlice'

const mobileWidth = 768

function NavigationBar () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [logoUrl, setLogoUrl] = useState(phinDefault)
  const { token, darkMode, isLMS, todoItemCount } = useSelector((state) => state.core)
  const selectedButtonColor = darkMode ? '#052460' : '#0D5D6A'
  const onGrowthPage = location.pathname.includes('my-growth')
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth)

  const { data: user } = useGetUserQuery(token, { skip: !token })

  function handleWindowSizeChange () {
    setIsMobile(window.innerWidth < mobileWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    const asynchronouslyFetchLogo = async () => {
      let response
      try {
        response = await fetch(`/api/logo?token=${token}`)

        const { logoUrl } = await response.json()

        if (logoUrl !== null) {
          setLogoUrl(logoUrl)
        } else {
          console.error('Failed to load user specific logo. Rendering default logo instead.')
        }
      } catch (error) {
        console.error('Failed to load user specific logo. Rendering default logo instead.')
      }
    }

    if (token) {
      asynchronouslyFetchLogo()
    }
  }, [token])

  function goHome () {
    if (isLMS && token) {
      navigate(`/${token}`)
    }
  }

  return (
    <div className='nav-bar'>
      <div className='d-flex flex-row justify-content-between align-items-center h-100'>

        <div>
          {user && user.first &&
            <>
              {isMobile
                ? (
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ marginLeft: 'var(--phin-s0)', color: 'var(--phin-white)' }}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '1.3em',
                        height: '1.3em',
                        background: '#000',
                        color: '#FFF',
                        textAlign: 'center',
                        paddingTop: '0.1em',
                        font: '2em Arial, sans-serif'
                      }}
                    >
                      <Badge badgeContent={todoItemCount} color='error'>
                        {user.first.toUpperCase()[0]}
                      </Badge>
                    </div>
                  </Grid>
                  )
                : (
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{ marginLeft: 'var(--phin-s2)', color: 'var(--phin-white)' }}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '1.3em',
                        height: '1.3em',
                        background: '#000',
                        color: '#FFF',
                        textAlign: 'center',
                        paddingTop: '0.1em',
                        font: '2em Arial, sans-serif'
                      }}
                    >{user.first.toUpperCase()[0]}
                    </div>
                    {user &&
                      <span className='padding-left:-1 padding-right:-1 user-collapse'>{`${user.first} ${user.last}`}</span>}
                    {(todoItemCount !== 0) &&
                      <Tooltip
                        toolTipText='# of incomplete assignments'
                        hoverElm={(
                          <Badge badgeContent={todoItemCount} color='error'>
                            <IoNotifications size='1.25em' />
                          </Badge>
              )}
                      />}
                  </Grid>
                  )}
            </>}
        </div>

        <div className='d-flex flex-row justify-content-center align-items-center'>
          {(isLMS) && (
            <Box sx={{ display: 'flex', color: 'white', justifyContent: 'space-evenly', alignItems: 'center', marginRight: '1rem' }}>
              <Typography onClick={() => goHome()} className='nav-view-btn' sx={{ background: !onGrowthPage && selectedButtonColor }}>Courses</Typography>
              <Typography onClick={() => navigate(`/${token}/analytics/my-growth`)} className='nav-view-btn' sx={{ background: onGrowthPage && selectedButtonColor }}>My Growth</Typography>
            </Box>
          )}

          {!isMobile && (
            <>
              <IoSunny size='1.25em' style={{ marginRight: 'var(--phin-s-2)', color: 'var(--phin-white)' }} />
              <label className='switch'>
                <input type='checkbox' checked={darkMode} onChange={() => dispatch(setDarkModeAction(!darkMode))} />
                <span className='slider round' />
              </label>
              <IoMoon size='1.25em' style={{ marginLeft: 'var(--phin-s-2)', color: 'var(--phin-white)' }} />
            </>
          )}

          <img onClick={() => goHome()} className='logo' src={logoUrl} />
        </div>
      </div>
    </div>
  )
}

export default NavigationBar

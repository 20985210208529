import { Grid } from '@mui/material'

function LandingPage () {
  return (
    <>
      <div className='main container-padding-smallish'>
        <Grid
          container
          direction='column'
          justifyContent='start'
          alignItems='center'
        >
          <h2>Welcome to the Learning Management System</h2>
          <p>Please check your email for your links to your learning content.
            For any other help please contact your administrator for assistance.
          </p>
        </Grid>
      </div>
    </>
  )
}

export default LandingPage
